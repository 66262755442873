<template>
  <b-modal
    id="modal-job-detail"
    ref="refModalJobDetail"
    title="Thông tin chi tiết"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <b-button
        v-if="itemLocal.type !== 'export_invoice_pdf' && itemLocal.type !== 'export_invoice_images'"
        variant="success"
        @click="reRunJob"
      >
        <feather-icon
          icon="UploadCloudIcon"
          class="mr-50"
        />
        <span class="align-middle">Chạy lại</span>
      </b-button>
      <b-button
        variant="warning"
        @click="downloadFile"
      >
        <feather-icon
          icon="DownloadCloudIcon"
          class="mr-50"
        />
        <span class="align-middle">Tải file</span>
      </b-button>
      <job-table :rows="itemLocal.items" />
    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
  BButton,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useJobDetailModal from './useJobDetailModal';
import JobTable from './JobTable.vue';

export default {
  components: {
    BModal,
    BSpinner,
    JobTable,
    BButton,
  },
  props: {
    jobId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
      reRunJob,
      downloadFile,
    } = useJobDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      reRunJob,
      downloadFile,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
